<template>
    <div id="job_news">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="uilchilgeenii_nuhtsul_title">
                        <span class="yelow">{{'dagah' | translate}}</span>{{'baiguullaguud' | translate}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="job_news_tabs">
                        <Tabs type="card">
                            <TabPane :label="'baiguullaga' | translate">
                                <div class="ajil_olgogch_gvitsedgegch">
                                    <div id="companies">
                                      <div v-if="loading" class="loading-wrap">
                                        <div class="lds-ellipsis">
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                        </div>
                                      </div>
                                      <ul class="logo-group" v-if="ds_baiguullaga_dagah_view.length > 0">
                                        <li class="logo-item" :key="index" v-for="(baiguullaga_dagah, index) in ds_baiguullaga_dagah_view">
                                          <div>
                                            <a href="javascript:void(0);" class="close" @click="deleteBaiguullagaData(baiguullaga_dagah.id)"><i class="ivu-icon ivu-icon-md-close" style="font-size: 16px"></i></a>
<!--                                            <delete_follow_btn :baiguullaga_id="baiguullaga_dagah.id" type="baiguullaga"></delete_follow_btn>-->
                                          </div>
                                          <router-link :to="`/baiguullaga_tanilts/${baiguullaga_dagah.baiguullaga_id}`">
                                            <img :src="IMAGE_URL + baiguullaga_dagah.logo" alt="" v-if="baiguullaga_dagah.logo != ''">
                                            <img src="../assets/no-image.png" alt="" v-else>
                                            <span class="span">{{ baiguullaga_dagah.baiguullaga_ner }}</span>
                                          </router-link>
                                        </li>
                                      </ul>
                                      <div v-else class="no-data">
                                          {{ 'no_saved_organization' | translate }}
                                      </div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane :label="'brigad' | translate">
                                <div class="ajil_olgogch_gvitsedgegch">
                                    <div id="companies1">
                                      <div v-if="loading" class="loading-wrap">
                                        <div class="lds-ellipsis">
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                        </div>
                                      </div>
                                        <ul class="logo-group" v-if="ds_brigad_dagah_view.length > 0">
                                            <li class="logo-item" :key="index1" v-for="(brigad_dagah, index1) in ds_brigad_dagah_view">
                                              <div>
                                                <a href="javascript:void(0);" class="close" @click="deleteBrigadData(brigad_dagah.id)"><i class="ivu-icon ivu-icon-md-close" style="font-size: 16px"></i></a>
                                              </div>
<!--                                                <div>-->
<!--                                                    <img class="logo" src="../assets/company_logo/1_gold.svg" alt="">-->
<!--                                                </div>-->
                                                <router-link :to="`/baiguullaga_tanilts/${brigad_dagah.brigad_id}`">
                                                  <img :src="IMAGE_URL + brigad_dagah.logo" alt="" v-if="brigad_dagah.logo != ''">
                                                  <img src="../assets/no-image.png" alt="" v-else>
                                                  <span class="span">{{ brigad_dagah.ner_mn }}</span>
                                                </router-link>
                                            </li>
                                        </ul>
                                        <div v-else class="no-data">
                                            {{ 'no_saved_brigade' | translate }}
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


import {
  GET_SAVE_BAIGUULLAGA,
  GET_SAVE_BRIGAD,
  IMAGE_URL,
  POST_DELETE_BAIGUULAGA,
  POST_DELETE_BRIGAD,
} from "../graphql/queries";
import {mapGetters} from "vuex";

export default {
    name: "flowed",
    data() {
        return {
          loading: true,
          ds_baiguullaga_dagah_view: [],
          ds_brigad_dagah_view: [],
          IMAGE_URL: IMAGE_URL,
        }
    },
    computed: {
       ...mapGetters({
         user: "user",
      }),
    },
    methods: {
      getGET_SAVE_BAIGUULLAGA() {
        this.user_id = this.user.id
        this.loading = true;
        this.$apollo.query({query: GET_SAVE_BAIGUULLAGA, fetchPolicy: 'no-cache', variables: {user_id: this.user_id.toString()}}).then(({data}) => {
          this.ds_baiguullaga_dagah_view = data.ds_baiguullaga_dagah_view;
          //console.log(this.ds_baiguullaga_dagah_view);
          this.loading = false;
        })
      },
      deleteBaiguullagaData(id){
        this.$apollo.mutate({mutation: POST_DELETE_BAIGUULAGA,
          variables: {
            baiguullaga_id: id,
          }
        }).then(() =>{
          this.$Notice.success({title: this.$t("successfully_Deleted"),});
          let deleteIndex = this.ds_baiguullaga_dagah_view.findIndex(item=>item.id == id);
          if(deleteIndex>=0){
            this.ds_baiguullaga_dagah_view.splice(deleteIndex, 1);
            this.$store.commit("removeFromBaiguullaga",  id);
          }
        });
      },
      getGET_SAVE_BRIGAD() {
        this.loading = true;
        this.user_id = this.user.id
        this.$apollo.query({query: GET_SAVE_BRIGAD, fetchPolicy: 'no-cache', variables: {user_id: this.user_id.toString()}}).then(({data}) => {
          this.ds_brigad_dagah_view = data.ds_brigad_dagah_view;
          //console.log(this.ds_brigad_dagah_view);
          this.loading = false;
        })
      },
      deleteBrigadData(id){
        this.$apollo.mutate({mutation: POST_DELETE_BRIGAD,
          variables: {
            brigad_id: id,
          }
        }).then(() =>{
          this.$Notice.success({title: this.$t("successfully_Deleted"),});
          let deleteIndex = this.ds_brigad_dagah_view.findIndex(item=>item.id == id);
          if(deleteIndex>=0){
            this.ds_brigad_dagah_view.splice(deleteIndex, 1);
            this.$store.commit("removeFromBrigad",  id);
          }
        });
      },

    },
    mounted() {
      this.getGET_SAVE_BAIGUULLAGA();
      this.getGET_SAVE_BRIGAD();
    }

}
</script>

<style scoped>

</style>
